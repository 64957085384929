import splice from './splice.js';

const removeValue = (arr, value) => {
  const index = arr.indexOf(value);

  if (index === -1) {
    return [...arr];
  }

  return splice(arr, index, 1);
};

export default removeValue;