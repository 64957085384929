import { REQUEST_COMPLETED, REQUEST_ERRORED, REQUEST_FLUSH, REQUEST_PROCESSED, REQUEST_STARTED } from './types.js';

const shouldSkip = (state, name, request) =>
  state[name]?.request !== request || state[name]?.abortController?.signal?.aborted;

const requestStarted = (state, {
  name,
  method,
  url,
  options,
  abortController,
  request
}) => {
  state[name]?.abortController?.abort(); // Abort older request

  return { ...state, [name]: { name, method, url, options, abortController, request } };
};

const requestCompleted = (state, {
  dataType,
  name,
  response,
  request,
  data,
  status,
  ok
}) => shouldSkip(state, name, request)
  ? state
  : { ...state, [name]: { ...state[name], dataType, response, data, status, ok, completed: true } };

const requestErrored = (state, {
  name,
  error,
  response,
  request,
  status
}) => {
  if (shouldSkip(state, name, request)) return state;

  console.error('HTTP Request Error', error?.message, error?.stack);

  return { ...state, [name]: { ...state[name], error, response, status, completed: true } };
};

const requestProcessed = (state, { name }) => ({
  ...state, [name]: { ...state[name], processed: true }
});

const requestFlush = (state, { name }) => ({
  ...state, [name]: undefined
});

const reducers = {
  [REQUEST_STARTED]: requestStarted,
  [REQUEST_COMPLETED]: requestCompleted,
  [REQUEST_ERRORED]: requestErrored,
  [REQUEST_PROCESSED]: requestProcessed,
  [REQUEST_FLUSH]: requestFlush
};

const http = (state = {}, action) =>
  (reducers[action.type] || (state => state))(state, action);

export default http;