import splice from './splice.js';

// If valueToReplace is not present, newValue will be added to the end of the array.
const replaceValue = (arr, valueToReplace, newValue) => {
  const index = arr.indexOf(valueToReplace);

  if (index === -1) {
    return [...arr, newValue];
  }

  return splice(arr, index, 1, newValue);
};

export default replaceValue;