import {
  ADD_TOAST,
  CLEAR_TOAST,
  UPDATE_TOAST
} from './types.js';
import removeValueMatching from '../../immutable/array/removeValueMatching.js';
import replaceValueMatching from '../../immutable/array/replaceValueMatching.js';
import replaceValue from '../../immutable/array/replaceValue.js';

const INITIAL_STATE = {};

const addToast = (state, { message, color, id, state: toastState }) =>
  ({
    ...state,
    toasts: replaceValueMatching(
      state.toasts || [],
      toast => toast.id === id,
      { message, color, id, state: toastState }
    )
  });

const updateToast = (state, { id, state: toastState }) => {
  const toast = state.toasts?.find(toast => toast.id === id);

  return ({
    ...state,
    toasts: toast
      ? replaceValue(state.toasts, toast, { ...toast, state: toastState })
      : []
  });
};

const clearToast = (state, { id }) =>
  ({
    ...state,
    toasts: removeValueMatching(state.toasts || [], toast => toast.id === id)
  });

const reducers = {
  [ADD_TOAST]: addToast,
  [CLEAR_TOAST]: clearToast,
  [UPDATE_TOAST]: updateToast
};

const toasts = (state = INITIAL_STATE, action) =>
  (reducers[action.type] || (state => state))(state, action);

export default toasts;
